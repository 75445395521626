<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/lubricationTask' }">润滑计划</el-breadcrumb-item>
          <el-breadcrumb-item>新增计划</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <!-- 基本信息 -->
          <div class="form-title">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="润滑代号" prop="name">
                <el-input v-model="form.name" placeholder="请输入润滑代号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="润滑类型" prop="lubeTypeIds">
                <el-select v-model="form.lubeTypeIds" placeholder="请选择润滑类型" style="width: 335px">
                  <el-option
                    v-for="item in lubeType"
                    :label="item.name"
                    :value="item.id"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="form-device-info">
            <div class="form-title">
              设备信息<span style="color: red"> *</span>
            </div>
            <div style="padding-left: 40px">
              <el-button @click="addDevice" type="text">添加设备</el-button>
            </div>
          </div>
          <div class="table-list" style="margin-bottom:20px;">
            <el-table :data="aliasObj" style="width: 100%">
              <el-table-column label="序号" type="index" width="50"></el-table-column>
              <el-table-column prop="alias" label="设备别名"></el-table-column>
              <el-table-column prop="materialsCode" label="设备识别号"></el-table-column>
              <el-table-column prop="archivesName" label="设备名称"></el-table-column>
              <el-table-column prop="specification" label="规格参数"></el-table-column>
              <el-table-column prop="typeName" label="设备类型"></el-table-column>
              <el-table-column prop="patrolName" label="巡视点名称"></el-table-column>
              <el-table-column prop="facilityName" label="设施位置"></el-table-column>
              <el-table-column label="操作" width="180">
                <template slot-scope="scope">
                  <el-dropdown placement="bottom-start" trigger="click">
                    <span @click.stop="">
                      <span class="el-dropdown-link">
                        操作<i class="el-icon-arrow-down"></i>
                      </span>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item><div @click="dlt(scope.row)">删除</div></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
            <!-- <page :size="size" :page="page" :total="total" v-on:pageChange="pageChange"></page> -->
          </div>

          <div class="form-title">
            润滑人员配置<span style="color: red"> *</span>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="润滑人员">
                <div @click="addStaff()" class="staffBox">
                  <div v-if="staffList.length>0">
                    <el-tag v-for="staff in staffList" :key="staff.id" style="margin-right: 4px;">
                      {{ staff.name }}
                    </el-tag>
                  </div>
                  <div v-else style="color: #909399;text-align: center;">请选择润滑人员</div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="起止日期" prop="date">
                <el-date-picker
                  :picker-options="pickerOptions"
                  style="width: 335px"
                  v-model="form.date"
                  type="daterange"
                  range-separator="至"
                  value-format="timestamp"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24" class="lubri-col">
              <div class="lubri-tabs">润滑周期选择</div>
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="自定义" name="custom">
                  <div class="config-box">
                    <el-row :gutter="20">
                      <el-col :span="12">
                        <el-form-item label="第一次润滑日期" label-width="140px">
                          <el-date-picker
                            :picker-options="pickerOptions"
                            v-model="form.firstLubriDate"
                            style="width: 335px"
                            value-format="timestamp"
                            type="date"
                            placeholder="选择日期">
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-row :gutter="20" class="cycles">
                          <el-col :span="9">
                            <el-form-item label="规律循环">
                              <el-input v-model="form.cycles" maxlength="4"></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="1" style="padding-left:0;">
                            <el-form-item label="天" label-width="0px"></el-form-item>
                          </el-col>
                          <el-col :span="13">
                            <el-form-item :label="`(每隔${form.cycles?form.cycles:'x'}天会自动生成一次润滑计划)`" label-width="270px"></el-form-item>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <div class="setWeek">
                        <div class="box">
                          <div class="week-title">
                            <el-form-item label="润滑工作时间段" label-width="115px">
                              <i @click="addLubriDate()" class="el-icon-plus"></i>
                            </el-form-item>
                          </div>
                          <div v-for="(cDate, cIndex) in form.customConfig" :key="cIndex" class="date">
                            <span class="date-num">{{cIndex+1}}.</span>
                            开始时间：
                            <span style="position: relative;">
                              <lay-time :date="cDate[0]" :index="[cIndex, 0]" @change="laytimeLubriEnd"></lay-time>
                            </span>
                            
                            结束时间：
                            <span style="position: relative;">
                              <lay-time :date="cDate[1]" :index="[cIndex, 1]" @change="laytimeLubriEnd"></lay-time>
                            </span>
                            <i @click="deleteLubriDate(cIndex)" v-if="cIndex != 0" class="el-icon-close" style="margin-top: 20px;"></i>
                          </div>
                        </div>
                      </div>
                    </el-row>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="周配置" name="weekConfig">
                  <div class="config-box">
                    <el-form-item label="工作时间">
                      <el-checkbox-group v-model="form.config.weekList" @change="checkboxChange()" >
                        <el-checkbox label="星期一"></el-checkbox>
                        <el-checkbox label="星期二"></el-checkbox>
                        <el-checkbox label="星期三"></el-checkbox>
                        <el-checkbox label="星期四"></el-checkbox>
                        <el-checkbox label="星期五"></el-checkbox>
                        <el-checkbox label="星期六"></el-checkbox>
                        <el-checkbox label="星期日"></el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                    <div class="setWeek">
                      <div class="box" v-for="(value, vKey) in form.config.weekDateList" :key="vKey">
                        <div class="week-title">
                          {{ vKey }}
                          <i @click="addWeekDate(value)" class="el-icon-plus"></i>
                        </div>
                        <div v-for="(date, dIndex) in value.dates" :key="dIndex" class="date">
                          <span class="date-num">{{dIndex+1}}.</span>
                          开始时间：
                          <span style="position: relative;">
                            <lay-time :date="date[0]" :index="[vKey, dIndex, 0]" @change="laytimeEnd"></lay-time>
                          </span>
                          结束时间：
                          <span style="position: relative;">
                            <lay-time :date="date[1]" :index="[vKey, dIndex, 1]" @change="laytimeEnd"></lay-time>
                          </span>
                          <i @click="deleteDate(value, dIndex)" v-if="dIndex != 0" class="el-icon-close" style="margin-top: 20px;"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </el-col>
          </el-row>
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/lubricationTask')">取消</el-button>
            <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
          </div>
        </el-form>
      </div>
      <el-drawer
        title="添加设备"
        :with-header="false"
        :visible.sync="deviceDrawer"
        direction="rtl"
        size="1200px">
        <div class="drawer-title">添加设备</div>
        <div class="drawer-content table-tree">
          <div class="tree">
            <div class="tree-title">设施位置名称</div>
            <el-tree
              :data="facility"
              node-key="id"
              @node-click="facilityClick"
              :props="defaultProps"
              :default-checked-keys="[1]"
              highlight-current
              ref="tree">
              <span class="custom-tree-node" slot-scope="{ node }">
                <span style="margin-right: 40px">{{ node.label }}</span>
              </span>
            </el-tree>
          </div>
          <div class="tree">
            <div class="tree-title">巡视点名称</div>
            <el-tree
              :data="patrolList"
              node-key="id"
              @node-click="patrolClick"
              :props="defaultPatProps"
              :default-checked-keys="[1]"
              highlight-current
              ref="tree">
              <span class="custom-tree-node" slot-scope="{ node }">
                <span style="margin-right: 40px">{{ node.label }}</span>
              </span>
            </el-tree>
          </div>
          <div class="table">
            <el-table :data="tableDataCp" height="700">
              <el-table-column label="勾选" width="70">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.checked" @change="checkeChange(scope.row)"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column prop="alias" label="设备别名"></el-table-column>
              <el-table-column prop="materialsCode" label="设备识别号"></el-table-column>
              <el-table-column prop="deviceName" label="设备名称"></el-table-column>
              <el-table-column prop="specification" label="规格参数"></el-table-column>
              <el-table-column prop="deviceTypeName" label="设备类型"></el-table-column>
            </el-table>
          </div>
        </div>
        <div class="drawer-bottom">
          <el-button size="small" type="primary" @click="deviceDrawer=false">取消</el-button>
          <el-button
            size="small"
            type="primary"
            @click="deviceDrawerOK"
            class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
      <el-drawer
        title=""
        :with-header="false"
        :visible.sync="drawer"
        direction="rtl"
        size="900px">
        <div class="drawer-title">选择润滑员</div>
        <div class="drawer-content" v-if="drawer">
          <div class="table-tree">
            <div class="tree">
              <div class="tree-title"><i class="el-icon-share"></i> 结构</div>
              <el-tree
                :data="treeData"
                @node-click="treeRowClick"
                :props="defaultProps"
                :expand-on-click-node="false"
                default-expand-all
                ref="tree">
                <span class="custom-tree-node" slot-scope="{ node }">
                  <span style="margin-right: 40px"><i class="el-icon-folder-opened"></i> {{ node.label }}</span>
                </span>
              </el-tree>
            </div>
            
            <div class="table">
              <el-table :data="tableData" style="width: 100%" height="700">
                <el-table-column width="40">
                  <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.check" :checked="staffListID.includes(scope.row.id)" @change="checked(scope.row)"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="sex" label="性别">
                  <template slot-scope="scope">
                    <span v-if="scope.row.sex == 1">男</span>
                    <span v-else>女</span>
                  </template>
                </el-table-column>
                <el-table-column prop="departmentName" label="部门"></el-table-column>
                <el-table-column prop="name" label="岗位">
                  <template slot-scope="scope">
                    <div
                      v-for="item in scope.row.positionInfoList"
                      :key="item.positionId"
                    >{{item.positionName}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="phone" label="电话"></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="drawer-bottom">
          <el-button size="small" type="primary" @click="drawer=false">取消</el-button>
          <el-button size="small" type="primary" @click="drawerOK" class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import layTime from '@/components/layTime'
import wMessage from '../../js/reWriteMessage.js'

export default {
  components: {
    layTime
  },
  data() {
    return {
      loading: false,
      form: {
        config: {
          weekList: [],
          weekDateList: {}
        },
        customConfig: []
      },
      rules: {
        name:[{ required: true, message: '必填项', trigger: 'blur' }],
        lubeTypeIds:[{ required: true, message: '必填项', trigger: 'blur' }],
        date:[{ required: true, message: '必填项', trigger: 'blur' }],
        firstLubriDate:[{ required: true, message: '必填项', trigger: 'blur' }]
      },
      props: {
        value: 'id',
        label: 'name',
        children: 'childTree'
      },
      options: [],
      value: [],
      lubeType: [
        { id:0, name:'补加油' },
        { id:1, name:'换底油' }
      ],
      facility: [], // 设施列表
      patrolList: [],
      staffList: [], // 巡检员列表
      index: 1,
      facilityMo: [],
      isToggle: false,
      // 添加设备初始变量
      deviceDrawer: false,
      tableDataCp: [],
      tableSelectList: [],
      deviceTableData: [],
      facilityId: 0, // 设施Id
      facilityName: '', // 设施位置
      patrolId: 0, // 巡视点Id
      patrolName: '', // 巡视点名称
      aliasObj: [],
      // tabs
      activeName:'custom',
      // 增加配置初始变量
      drawer: false,
      treeData: [],
      tableData: [],
      defaultProps: {
        children: 'childTree',
        label: 'name'
      },
      defaultPatProps: {
        children: 'childTree',
        label: 'patrolName'
      },
      checkedStaffList: [],
      staffListID: [],
      configKey: '',
      pickerOptions: {
        disabledDate: time => {
          let d = dayjs(time).format("YYYYMMDD").valueOf();
          let n = dayjs().format("YYYYMMDD").valueOf();
          return d < n;
        }
      },
    }
  },
  methods: {
    // 添加设备
    addDevice() {
      this.deviceDrawer = true;
      this.tableSelectList = [];
      this.tableDataCp = []
      this.deviceTableData.forEach(item => {
        let d = JSON.parse(JSON.stringify(item));
        d.checked = false
        this.tableDataCp.push(d)
      })
      this.isPat()
    },
    isPat() {
      // 选择的设备信息列表不为空
      if(this.aliasObj.length!=0) {
        this.isToggle = true
        this.facility.forEach((v, k) => {
          if(v.id != this.facilityId) {
            this.facilityMo.push(v)
            this.facility.splice(k, 1)
            return
          }
        })
        // this.patrolList.forEach((item, index)=> {
        //   if(item.id != this.patrolId) {
        //     this.patrolMo.push(item)
        //     this.patrolList.splice(index, 1)
        //   }
        // })
      }else {
        this.isToggle = false
        this.facilityMo.forEach(v => {
          this.facility.push(v)
        })
        // this.patrolMo.forEach(v => {
        //   this.patrolList.push(v)
        // })
      }
    },
    // 删除设备
    dlt(row) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.aliasObj.forEach((v, k) => {
          if(v.patrolDeviceId == row.patrolDeviceId){
            this.aliasObj.splice(k, 1)
          }
        })
      });
    },
    // 勾选设备
    checkeChange(row) {
      if (row.checked) {
        // 勾选
        this.tableSelectList.push(row)
      } else {
        this.tableSelectList.forEach((item, index) => {
          if (item.patrolDeviceId == row.patrolDeviceId) {
            this.tableSelectList.splice(index, 1)
          }
        })
      }
    },
    // 选择设备点击确定
    deviceDrawerOK(){
      this.tableSelectList.forEach(item => {
        let arr = this.aliasObj.every(v => v.patrolDeviceId != item.patrolDeviceId)
        if(arr) {
          this.$ajax.post("deviceDetail", { id: item.archivesId }).then((res) => {
            res.data.alias = item.alias;
            res.data.applyTime = item.applyTime;
            res.data.patrolDeviceId = item.patrolDeviceId;
            res.data.facilityId = this.facilityId;
            res.data.facilityName = this.facilityName;
            res.data.patrolId = this.patrolId;
            res.data.patrolName = this.patrolName;
            this.aliasObj.push(res.data)
          });
          this.deviceDrawer = false;
          return
        }
        wMessage.error('此设备已存在，请重新选择!')
      })
    },
    // 获取设施名称
    load_facility() {
      this.$ajax.get("facilityQueryAll").then((res) => {
        this.facility = res.data;
        this.facilityId = res.data[0].id
        this.facilityName = res.data[0].name
      });
    },
    // 获取巡视点名称
    loadPatrolList(id) {
      this.$ajax.post("patrolList", {
        facilityId: id ? id : 1
      }).then((res) => {
        this.patrolList = res.data;
        if(id==undefined) {
          this.patrolId = res.data[0].id
          this.patrolName = res.data[0].patrolName
          this.loadListData(this.patrolId)
        }
      });
    },
    facilityClick(data) {
      if(this.facilityId == data.id) {
        this.loadPatrolList(data.id)
        this.facilityId = data.id
        this.facilityName = data.name
      }
      if(!this.isToggle) {
        this.loadPatrolList(data.id)
        this.facilityId = data.id
        this.facilityName = data.name
      }
    },
    patrolClick(data) {
      this.tableSelectList = []
      if(this.patrolId == data.id) {
        this.loadListData(data.id)
        this.patrolId = data.id
        this.patrolName = data.patrolName
      }
      if(!this.isToggle) {
        this.loadListData(data.id)
        this.patrolId = data.id
        this.patrolName = data.patrolName
      }
    },
    // 获取巡视点设备列表
    loadListData(id) {
      this.$ajax.post("lubeDeviceList", {
        facilityId: 0,
        pageNum: 0,
        pageSize: 0,
        patrolId: id,
      }).then((res) => {
        this.deviceTableData = res.data;
        this.tableDataCp = []
        this.deviceTableData.forEach(item => {
          let d = JSON.parse(JSON.stringify(item));
          d.num = 1
          d.checked = false
          this.tableDataCp.push(d)
        })
      });
    },
    handleClick(tab, event){},
    // 选择巡检员
    addStaff() {
      this.loadTableData(this.treeData[0].id)
      this.staffListID = []
      this.staffList.forEach(item => {
        this.staffListID.push(item.id)
      })
      this.checkedStaffList = [].concat(this.staffList)
      this.drawer = true
    },
    // 获取部门结构树
    loadTreeData() {
      this.$ajax.post('departmentResponsibleChild').then(res => {
        this.treeData = res.data
      })
    },
    // 获取部门人员
    loadTableData(id) {
      this.$ajax.post('queryFilter', {
        departmentId: id ? id : 0
      }).then(res => {
        res.data.forEach(item => {
          let inIs = this.staffListID.find(function(obj) {
            return obj == item.id
          })
          if (!inIs) {
            item.check = false
          }else {
            item.check = true
          }
        })
        this.tableData = res.data
      })
    },
    // 选择部门结构
    treeRowClick(data) {
      this.loadTableData(data.id)
    },
    // 勾选巡检员
    checked(row) {
      // if (row.check) {
      if (!this.staffListID.includes(row.id)) {
        this.checkedStaffList.push(row)
        this.staffListID.push(row.id)
      } else {
        this.checkedStaffList.forEach((item, index) => {
          if (item.id == row.id) {
            this.checkedStaffList.splice(index, 1)
            this.$delete(this.staffListID, index)
            console.log(this.staffListID)
          }
        })
      }
    },
    // 选择巡检员点击确定
    drawerOK() {
      this.drawer = false
      this.staffList = [].concat(this.checkedStaffList)
      this.staffListID = [].concat(this.staffListID)
    },
    // 选择工作时间
    checkboxChange() {
      // 补缺
      this.form.config.weekList.forEach(week => {
        if (this.form.config.weekDateList[week] == undefined) {
          this.$set(this.form.config.weekDateList, week, {
            dates: [['00:00:00', '23:00:00']]
          })
        }
      })
      // 去无
      for (let d in this.form.config.weekDateList) {
        if (!this.form.config.weekList.includes(d)) {
          this.$delete(this.form.config.weekDateList, d)
        }
      }
    },
    // 周配置增加时间段
    addWeekDate(item) {
      item.dates.push(['00:00:00', '23:00:00'])
    },
    // 周配置删除时间段
    deleteDate(value, dIndex) {
      this.$delete(value.dates, dIndex)
    },
    // 周配置选择时间段
    laytimeEnd(index, date) {
      this.form.config.weekDateList[index[0]].dates[index[1]][index[2]] = date
    },
    // 自定义增加时间段
    addLubriDate(){
      this.form.customConfig.push(['00:00:00', '23:00:00'])
    },
    // 自定义删除时间段
    deleteLubriDate(cIndex){
      this.$delete(this.form.customConfig, cIndex)
    },
    // 自定义选择时间段
    laytimeLubriEnd(index, date){
      this.form.customConfig[index[0]][index[1]] = date
    },
    dateSecond(str) {
      let strArr = str.split(':')
      let second =  (strArr[0] * 3600) + (strArr[1] * 60) + Number(strArr[2])
      return second
    },
    // 提交任务
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let unsub = true
          let _this = this
          let form = {
            taskCode: this.form.name,
            lubeType: this.form.lubeTypeIds,
            facilityId: this.facilityId,
            patrolId: this.patrolId,
            deviceInfos: [],
            staffInfos: [],
            startDate: this.form.date[0],
            endDate: this.form.date[1],
            weekFrequencyInfos: [],
            customFrequencyInfo: {
              cycleDays: this.form.cycles,
              firstDate: this.form.firstLubriDate,
              timePeriods: []
            },
            cycle: this.activeName=='weekConfig'?0:1,
          } 
          
          // 添加多个设备
          this.aliasObj.forEach(item => {
            form.deviceInfos.push({
              alias: item.alias,
              archivesId: item.id,
              deviceName: item.archivesName,
              deviceTypeId: item.typeId,
              deviceTypeName: item.typeName,
              patrolDeviceId: item.patrolDeviceId,
              specification: item.specification
            })
          });

          // 添加润滑人员
          this.staffList.forEach((item)=>{
            form.staffInfos.push({
              staffId: item.id,
              staffName: item.name
            })
          })

          form.weekFrequencyInfos = getfrequencyDate(this.form.config.weekDateList)

          function getfrequencyDate (weekDateList) {
            var dataCycle = []
            for (let week in weekDateList) {
              let obj = {}
              let dateArr = []
              let weekIndex = ''
              if (week == '星期一') {
                weekIndex = 1
              } else if (week == '星期二') {
                weekIndex = 2
              } else if (week == '星期三') {
                weekIndex = 3
              } else if (week == '星期四') {
                weekIndex = 4
              } else if (week == '星期五') {
                weekIndex = 5
              } else if (week == '星期六') {
                weekIndex = 6
              } else if (week == '星期日') {
                weekIndex = 0
              }
              obj.weekIndex = weekIndex
              weekDateList[week].dates.forEach(a => {
                dateArr.push({
                  startTime: _this.dateSecond(a[0]),
                  endTime: _this.dateSecond(a[1])
                })
                obj.timePeriods = dateArr
                if (_this.dateSecond(a[0]) > _this.dateSecond(a[1])) {
                  unsub = false
                }
              })
              dataCycle.push(obj)
            }
            return dataCycle
          }
          if (form.deviceInfos.length == 0) {
            this.$message.error("设备信息不能为空！");
            return
          }
          if (form.staffInfos.length == 0) {
            this.$message.error("人员配置不能为空！");
            return
          }
          if(this.activeName=='weekConfig'){
            if (this.form.config.weekList.length == 0) {
              this.$message.error("请选择周配置工作时间！");
              return
            }
          }else {
            if (!this.form.firstLubriDate) {
              this.$message.error("第一次润滑日期不能为空！");
              return
            }
            if (!this.form.cycles) {
              this.$message.error("规律循环不能为空！");
              return
            } 
            if (this.form.customConfig.length == 0) {
              this.$message.error("请选择自定义工作时间！");
              return
            }
            
            this.form.customConfig.forEach(a=>{
              form.customFrequencyInfo.timePeriods.push({
                startTime: _this.dateSecond(a[0]),
                endTime: _this.dateSecond(a[1])
              })
            })
          }
          
          if (unsub) {
            // console.log(form)
            this.loading = true
            this.$ajax.post("lubeTaskCreate", form).then((res) => {
              this.loading = false
              this.$message.success("成功");
              this.$router.push("/lubricationTask");
            }).catch(err=>{
              this.loading = false
            })
          } else {
            this.$message.error("开始时间 不能大于 结束时间！");
          }
        }
      })
    }
  },
  mounted() {
    this.load_facility()
    this.loadPatrolList()
    // this.loadListData()
    this.loadTreeData()
  }
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-box {
  max-width: 1000px;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 110px)';
  margin-top: 20px;
  .tree {
    width: 220px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    &:hover {
      color: #409EFF;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.staffBox {
  cursor: pointer;
  width: 390px;
  background: #fff;
  margin-right: 20px;
  height: 40px;
  border-radius: 4px;
  padding: 0 4px;
  border: 1px solid #e7e9ee;
}
.el-icon-close {
  color: #1494fb;
  margin-right: 30px;
  cursor: pointer;
  float: right;
  font-size: 20px;
}
.config-box {
  background-color: #FAFAFA;
  border-radius: 10px;
  margin: 10px;
  padding: 30px 0 5px;
}
.setWeek {
  border-top: 0;
  .date {
    padding-bottom: 20px;
    padding-left: 80px;
    font-size: 14px;
  }
  .date-num {
    font-size: 12px;
    margin: 0 10px;
  }
  .week-title {
    font-size: 12px;
    padding-bottom: 10px;
    margin-left: 25px;
    i {
      font-weight: bold;
      color: #1494fb;
      margin-left: 20px;
      cursor: pointer;
    }
  }
  .title {
    font-size: 14px;
    padding-left: 10px;
    line-height: 30px;
    background-color: #f6f7fb;
  }
  .box {
    padding: 10px;
    margin-bottom: 6px;
  }
  .box:last-child {
    margin: 0;
  }
}
.checkbox {
  margin-bottom: 20px;
  padding-left: 40px;
}
.form-device-info{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lubri-col /deep/.el-tabs__nav{
  left: 140px;
}
.lubri-col /deep/.el-tabs__nav-wrap::after {
  position: static !important;
}
.lubri-tabs {
  font-size:14px;
  color:#606266;
  line-height: 40px;
  margin-left: 30px;
  position: absolute;
}
.cycles /deep/.el-input__inner {
  text-align: center;
} 
/deep/.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #409eff;
  color: white;
}
.drawer-bottom {
  position: static;
  z-index: 1;
  text-align: right;
  padding: 10px 20px 10px 0;
}
</style>
<style lang="less">
.el-drawer.rtl {
  overflow: scroll;
}
</style>